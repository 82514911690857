import dk from '@norsktest/dkdj';

export function pusher_client(pusher_key, pusher_cluster) {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;
    const pusher = new Pusher(pusher_key, {
        cluster: pusher_cluster,
        forceTLS: true
    });

    const channel = pusher.subscribe('finaut-idd-channel');
    channel.bind('register-idd-starting', function(data) {
        $('#register-idd-list').fadeTo(500, 0.2);
    });
    channel.bind('register-idd', function(data) {
        const cells = $(`tr[pk=${data.id}]`).children();
        cells.css('background-color', '#dc3545');
        cells.css('color', 'white');
        $(`tr[pk=${data.id}]`).fadeOut(1000,function() {
            $(`tr[pk=${data.id}]`).remove();
        });
    });
    channel.bind('register-idd-completed', function(data) {
        $('#register-idd-list').fadeTo(500, 1);
        $('#loading-spinner').hide();
        $('#submit-button').prop('disabled', false);
        resultset.dataset.reload_all();
    });
}

export function mark_all(button) {
    $(button).toggleClass("btn-danger btn-primary");
    if ($('input[type=checkbox]').prop('checked') === true) {
        $('input[type=checkbox]').prop('checked', false);
        $(button).html('Merk alle i lista');
    } else {
        $('input[type=checkbox]').prop('checked', true);
        $(button).html('Fjern alle markeringer');
    }
}

export function submit_marked_users(url, element_id, button) {
    const ok = confirm("Er du sikker på at du vil registrere fullført interne oppdateringen for alle valgte rådgivere?");
    if (ok === true) {
        $(button).prop('disabled', true);
        let checked = [];
        const csrftoken = jQuery("[name=csrfmiddlewaretoken]").val();
        $(element_id).fadeTo(500, 0.2);
        $('#loading-spinner').show();
        $('input[type=checkbox]:checked').each(function () {
            checked.push($(this)[0].id.split(':')[1]);
        });
        if (checked.length > 0) {
            $.post(url, {'users': checked, 'csrfmiddlewaretoken': csrftoken});
        } else {
            alert('Ingen rådgivere er valgt.');
            $(element_id).fadeTo(500, 1);
            $(button).prop('disabled', false);
            $('#loading-spinner').hide();
        }
    }
}

export function RegisterIdd(id, datasource_url) {
    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.Data.create({
            pagesize: 1000,
            datasource: dk.data.AjaxDataSource.create({
                url: datasource_url
            }),
        }),
        construct_table: function (location, downloadwidget, ds) {
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed table-sm'],
                table_data: ds,
                columns: {
                    register: {
                        label: 'Registrer',
                        format: function (val, rec) {
                            return `<input class="form-check-input" type="checkbox" value="" id="idd:${rec.id}">`;
                        }
                    },
                    username: {
                        label: 'Brukernavn',
                        format: function (val, rec) {
                            const persnr = val.split('-').pop();
                            return `<a target="_blank" href="/clr/${rec.id}/">${persnr}</a>`;
                        }
                    },
                    first_name: {
                        label: 'Fornavn',
                    },
                    last_name: {
                        label: 'Etternavn',
                    },
                    ac_number: {
                        label: 'Ansattnr.',
                    },
                    email: {
                        label: 'E-post',
                    },
                    company_name: {
                        label: 'Firma',
                    },
                    department_name: {
                        label: 'Avdeling',
                    },
                }
            });
        },
        command_buttons: {
            add: {
                show: false,
            },
            csv: {
                show: false,
            }
        }
    });
}
